import React from "react";

import AboutMe from "../components/AboutMe";
import Layout from "layouts/Layout";

const AboutMePage = () => {
  return (
    <Layout seoTitle="About Me">
      <AboutMe />
    </Layout>
  );
};

export default AboutMePage;
