import React, { useEffect, useContext } from "react";
import { gsap, Power4, Expo } from "gsap";

import Button from "elements/Button";

import "./style/aboutMe.scss";
import { AppContext } from "../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    let pageContentTimeLine = gsap.timeline();

    pageContentTimeLine.from(".animation-up", {
      duration: 0.5,
      delay: 1.3,
      stagger: 0.05,
      opacity: 0,
      y: 20,
    });

    //eslint-disable-next-line
  }, []);

  const handleShowContactForm = () => {
    dispatch({ type: "TOGGLE_CONTACT_ME" });

    let contactFormTimeline = gsap.timeline();
    let contactFormBackgroundTimeline = gsap.timeline();

    contactFormTimeline.to(".contact-me-form-container", {
      duration: 0.5,
      height: "auto",
      ease: Power4.easeOut,
    });

    contactFormTimeline.from(".contact-me-form-content", {
      ease: Power4.easeOut,
      duration: 0.5,
      opacity: 0,
      y: 20,
    });

    contactFormBackgroundTimeline.to(".contact-me-container", {
      ease: Expo.easeOut,
      duration: 1,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    });
  };

  return (
    <div className="about-me-page-container">
      <div className="is-flex image-container is-hidden-desktop is-justify-content-center is-align-items-center">
        <img
          src="/images/jpgs/thom.jpg"
          className="about-me-image"
          alt="JTCARLOS"
        />
      </div>
      <div className="columns is-desktop">
        <div className="column about-me animation-up">
          <h1 className="about-me-headline mb-2">
            I build applications that reaches people.{" "}
          </h1>
          <p className="mb-5">
            Hello! I'm John Thomas Carlos, a passionate BS Computer Engineering
            graduate from Adamson University with a strong dedication to the
            world of Software Development. I am currently working as a Senior
            Software Engineer at MDI Novare, with an overall 3 years of
            professional experience in the field.
          </p>
          <p className="mb-5">
            What drives me in the field of software development is my passion
            for creating innovative solutions that make a positive impact on
            people's lives. I thrive on challenges and am committed to
            delivering high-quality software that meets and exceeds client
            expectations.
          </p>
          <p className="mb-5">
            I specialize in developing web applications with knowledge and
            experience in coding scripts for automation and building mobile
            applications. I also have experience with hardware development such
            as PCB fabrication and working with sensors, motors, and SBCs.
          </p>
          <Button
            handleClick={handleShowContactForm}
            className="about-cta-button"
            color="primary"
          >
            Contact Me
            <span className="ml-3">
              <FontAwesomeIcon icon={faPhone} />
            </span>
          </Button>
        </div>
        <div className="column is-one-third is-hidden-touch animation-up">
          <div className="is-flex image-container is-justify-content-center is-align-items-center">
            <img
              src="/images/jpgs/thom.jpg"
              className="about-me-image"
              alt="JTCARLOS"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
